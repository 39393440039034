import { withExtTenantIdRequest } from '@/utils/request';
// 获取列表
export function list(data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/dropshippingOrder/listAll',
    method: 'post',
    data
  });
}
// 大贸确认提交订单
export function submitOrder(data) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/dropshippingOrder/submitOrder`,
    method: 'post',
    data
  });
}
// 海淘确认提交订单
export function submitGlobalOrder(data) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/dropshippingOrder/submitGlobalOrder?batchNo=${data}`,
    method: 'post'
  });
}
// 导入大贸订单
export function importExcel(data) {
  return withExtTenantIdRequest({
    url: `soyoungzg/api/dropshippingOrder/batchImport`,
    method: 'post',
    timeout: 30 * 10000,
    data
  });
}
// 导入海淘订单
export function batchImportGlobal(data) {
  return withExtTenantIdRequest({
    url: `soyoungzg/api/dropshippingOrder/batchImportGlobal`,
    method: 'post',
    timeout: 30 * 10000,
    data
  });
}
// 导出大贸错误订单(旧)
export function exportErrorOrder(data) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/dropshippingOrder/exportErrorOrder?batchNo=${data}`,
    method: 'post',
    responseType: 'arraybuffer',
    timeout: 30 * 1000
  });
}
// 导出大贸错误订单(新)
export function exportError(data, params) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/dropshippingOrder/exportError?batchNo=${data}`,
    method: 'post',
    data: params,
    responseType: 'arraybuffer',
    timeout: 30 * 1000
  });
}
// 导出海淘错误订单
export function exportErrorGlobalOrder(data) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/dropshippingOrder/exportErrorGlobalOrder?batchNo=${data}`,
    method: 'post',
    responseType: 'arraybuffer',
    timeout: 30 * 1000
  });
}
// 获取大贸单次导入概括
// export function getTotal(data) {
//   return withExtTenantIdRequest({
//     url: `/soyoungzg/api/dropshippingOrder/getTotal?bathNo=${data}`,
//     method: 'GET'
//   });
// }
export function getTotal(data) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/dropshippingOrder/calculatePayment`,
    method: 'post',
    data
  });
}
// 获取海淘单次导入概括
export function getGlobalTotal(data) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/dropshippingOrder/getGlobalTotal?bathNo=${data}`,
    method: 'GET'
  });
}
// 删除单个记录
export function handleDelete(id) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/dropshippingOrder/delete?id=${id}`,
    method: 'post'
  });
}
// 删除多个记录
export function handleDeleteIds(data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/dropshippingOrder/deleteByIds',
    method: 'post',
    data
  });
}

// 批量修改订单合同主体
export function butorContractInfo(data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/dropshippingOrder/batchUpdateDistributorContractInfo',
    method: 'post',
    data
  });
}

// 获取订单合同主体列表
export function getListOrderAvalibleContractInfo(data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/distributorContractInfo/listOrderAvalibleContractInfo',
    method: 'post',
    data
  });
}