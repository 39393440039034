<template>
  <div>
    <el-upload
      :accept="accept"
      :action="action"
      :before-remove="beforeRemove"
      :before-upload="onBeforeUpload"
      :file-list="fileList"
      :headers="headers"
      :on-error="onError"
      :on-preview="handlePreview"
      :on-remove="handleRemove"
      :on-success="onUploadSuccess"
      class="upload-demo"
      multiple
    >
      <el-button size="small" type="primary">{{ btnName }}</el-button>
      <div class="el-upload__tip" slot="tip">
        文件只支持上传大小为：2M以内，条数200条以内；文件格式支持：xlsx格式
      </div>
    </el-upload>
  </div>
</template>

<script>
import { getHeadersWithExtTenant } from '@/utils/request';
import { mapGetters } from 'vuex';
export default {
  name: 'uploadExcel',
  props: {
    btnName: {
      type: String,
      default: '上传文件'
    }, // 按钮名称
    action: {
      type: String,
      default: `${process.env.VUE_APP_BASE_API}/soyoungzg/api/dropshippingOrder/batchImport` // 文件导入api
    },
    accept: {
      type: String,
      default: '.xlsx'
    }
  },
  data() {
    return {
      fileList: []
    };
  },
  computed: {
    ...mapGetters(['userInfo']),
    headers() {
      const { userToken = '' } = this.userInfo || {};
      return {
        ...getHeadersWithExtTenant(),
        'X-User-Token': userToken
      };
    }
  },
  methods: {
    onUploadSuccess(response, ...args) {
      // 可以重复上传，但是每次只展示最后一次上传的文件
      this.fileList = [[...args][0]];
      this.$emit('getData', response, ...args);
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    onBeforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error('上传的文件大小不能超过 2MB!');
      }
      return isLt2M;
    },
    onError(err, file, fileList) {
      console.log(err);
      this.$message.error('服务器内部错误，上传失败，请稍后再试');
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
