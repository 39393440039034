<template>
  <div class="app-container wrap">
    <div class="tips">
      <p>海淘一件代发导入说明</p>
      <p>A、该功能主要用于商家通过其他方式销售了商品产生的订单或是批量代理下单来使用的；</p>
      <p>B、海淘一件代发的商品支持的为我们的海淘商品；</p>
      <p>C、海淘一件代发目前仅支持使用微信或是支付宝支付；</p>
    </div>
    <div class="table-container" v-if="!showTable">
      <div class="top-container">
        <UploadExcel :action="uploadExcelUrl" @getData="getData"></UploadExcel>
        <el-button
          class="btn-download"
          @click="onDownload"
          size="small"
          style="border: 1px solid #AB0033; color: #AB0033; margin-left: -310px; height: 32px"
          >下载模板</el-button
        >
        <div style="flex: 1"></div>
        <router-link to="/syoung/introduce/drop_shipping_order" class="link-type" target="_blank"
          >查看帮助文档</router-link
        >
      </div>
    </div>
    <div class="table-container" v-if="showTable">
      <div class="top-container">
        <div class="top-container-left">
          <UploadExcel :action="uploadExcelUrl" @getData="getData" btnName="重新上传"></UploadExcel>
        </div>
        <el-button
          @click="onDownload"
          size="small"
          style="border: 1px solid #AB0033; color: #AB0033; margin-left: -310px; height: 32px"
          >下载模板</el-button
        >
      </div>
      <div class="order-num-detail order-num-bg">
        <span>
          本次导入
          <span class="red">{{ totalDetail.orderQuantity }}</span>
          笔订单,上传成功
          <span class="red">{{ totalDetail.orderSuccessQuantity }}</span
          >条,失败
          <span class="red">{{ totalDetail.orderFailQuantity }}</span>
          条,批次号：{{ batchNo }}
          <el-button
            :loading="exportLoading"
            @click="onExport"
            type="text"
            v-if="totalDetail.orderFailQuantity"
            >下载失败订单</el-button
          >
        </span>
      </div>
    </div>
    <div class="table-container" v-if="showTable">
      <el-checkbox
        :indeterminate="isIndeterminate"
        @change="handleCheckAllChange"
        v-if="options.length > 0"
        v-model="checkAll"
        >全选</el-checkbox
      >
      <el-button @click="handleDeleteIds" type="text" v-if="options.length > 0">批量删除</el-button>
      <div class="table">
        <el-checkbox-group @change="handleCheckListChange" v-model="checkList">
          <table border="0" cellpadding="0" cellspacing="0" class="refund-list" style="width: 100%">
            <thead class="table-head">
              <tr class="line">
                <th style="width: 10%">
                  <div class="cell">商品信息</div>
                </th>
                <th style="width: 10%">
                  <div class="cell">规格</div>
                </th>
                <th>
                  <div class="cell">批发价</div>
                </th>
                <th>
                  <div class="cell">数量</div>
                </th>
                <th>
                  <div class="cell">小计</div>
                </th>
                <th>
                  <div class="cell">操作</div>
                </th>
              </tr>
            </thead>
            <tbody :key="index" class="table-body" v-for="(item, index) in list">
              <tr class="line tips-container">
                <td class="tips-content" colspan="5">
                  <div class="cell">
                    <el-checkbox :label="item.id" v-if="item.status === 'IMPORT_FAIL'"
                      >买家订单号：{{ item.buyerOrderNo }}</el-checkbox
                    >
                    <span v-else>买家订单号：{{ item.buyerOrderNo }}</span>
                    <span class="consignee-info">身份证信息：</span>
                    {{ item.buyerName }}&nbsp;{{ item.idcardNo }}
                    <span class="consignee-info">收货信息：</span>
                    {{ item.consigneeInfo.consignee }}&nbsp;&nbsp;{{
                      item.consigneeInfo.mobile
                    }}&nbsp;&nbsp;{{ item.consigneeInfo.consigneeAddress }}
                  </div>
                </td>
                <td class="tips-content" colspan="1">
                  <div class="cell">
                    <p style="text-align: center">
                      <el-tooltip :content="item.errMsgs" placement="top" v-if="item.errMsgs">
                        <span class="btn-error" type="text">数据错误</span>
                      </el-tooltip>
                      <el-button
                        @click="handleDelete(item.id)"
                        type="text"
                        v-if="item.status === 'IMPORT_FAIL'"
                        >删除</el-button
                      >
                    </p>
                  </div>
                </td>
              </tr>
              <tr class="line">
                <!-- 商品信息 -->
                <td class="good-img" style="width: 10%">
                  <div
                    :key="idx"
                    class="commodity-wrap commodity-wrap--left"
                    v-for="(datas, idx) in item.dropshippingOrderDetailVOList"
                  >
                    <img :src="datas.imgUrl" class="table-img" />
                    <p class="commo-ellipsis-2" v-if="datas.commodityName">
                      {{ datas.commodityName }}
                    </p>
                  </div>
                </td>
                <!-- 规格 -->
                <td style="width: 10%">
                  <div
                    :key="idx"
                    class="commodity-wrap"
                    v-for="(datas, idx) in item.dropshippingOrderDetailVOList"
                  >
                    <div class="commo-ellipsis">
                      <p v-if="datas.commoditySpecValue">{{ datas.commoditySpecValue }}</p>
                      <el-tooltip effect="light" :content="datas.skuId" placement="bottom-start">
                        <span> 规格标识: {{ datas.skuId }}</span></el-tooltip
                      >
                    </div>
                  </div>
                </td>
                <!-- 批发价 -->
                <td>
                  <div
                    :key="idx"
                    class="commodity-wrap price"
                    v-for="(datas, idx) in item.dropshippingOrderDetailVOList"
                  >
                    ¥{{ datas.price }}
                  </div>
                </td>
                <!-- 数量 -->
                <td>
                  <div
                    :key="idx"
                    class="commodity-wrap"
                    v-for="(datas, idx) in item.dropshippingOrderDetailVOList"
                  >
                    {{ datas.quantity }}
                  </div>
                </td>
                <!-- 金额 -->
                <td>
                  <div
                    :key="idx"
                    class="commodity-wrap price"
                    v-for="(datas, idx) in item.dropshippingOrderDetailVOList"
                  >
                    ¥{{ datas.saleAmount }}
                  </div>
                </td>
                <!-- 操作 -->
                <td>
                  <div
                    :key="idx"
                    class="commodity-wrap error-wrap"
                    v-for="(datas, idx) in item.dropshippingOrderDetailVOList"
                  >
                    <el-tooltip :content="datas.errMsgs" placement="top" v-if="datas.errMsgs">
                      <span class="btn-error btn-error-margin" type="text">数据错误</span>
                    </el-tooltip>
                  </div>
                </td>
              </tr>
              <div class="div-blank"></div>
            </tbody>
          </table>
        </el-checkbox-group>
        <div class="footer" v-if="showTable">
          <p>
            本次导入
            <span class="red">{{ totalDetail.orderQuantity }}</span
            >笔订单,上传成功 <span class="red">{{ totalDetail.orderSuccessQuantity }}</span
            >条,失败 <span class="red">{{ totalDetail.orderFailQuantity }}</span
            >条
            <el-button @click="onExport" type="text" v-if="totalDetail.orderFailQuantity"
              >下载失败订单</el-button
            >
          </p>
          <div class="footer__content">
            <p class="footer__content__title">订单汇总信息</p>
            <div class="total">
              <p>本次订购订单总数量：{{ totalDetail.orderQuantity }}笔</p>
              <p>商品总数量：{{ totalDetail.commodityQuantity }}件</p>
              <p>商品总金额：¥{{ totalDetail.saleAmount }}</p>
            </div>
            <p class="all-price">
              订单支付金额：<span>¥{{ totalDetail.payAmount }}</span>
            </p>
          </div>
        </div>
        <div class="btn-wrap">
          <button
            :class="[
              'btn-no-radius',
              totalDetail.orderFailQuantity > 0
                ? 'btn-no-radius--disabled'
                : 'btn-no-radius--default'
            ]"
            :disabled="totalDetail.orderFailQuantity > 0"
            :loading="submitLoding"
            @click="submit"
            type="primary"
          >
            提交订单
          </button>
        </div>
        <div class="haitao-tips">
          <i class="iconfont icon-tishi"></i>
          <span>请先提交订单再支付:海淘一件代发订单,需要先提交订单,然后去订单列表一笔一笔支付</span>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible" center title="提示" width="30%" class="dialog-word">
      <p :key="index" v-for="(item, index) in errorArr">{{ index + 1 }}.{{ item }}</p>
      <span class="dialog-footer" slot="footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button @click="dialogVisible = false" type="primary">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import download from '@/utils/download';
import { parseTime } from '@/utils';
import UploadExcel from '@/components/UploadExcel';
import {
  list,
  getGlobalTotal,
  handleDelete,
  handleDeleteIds,
  submitGlobalOrder,
  exportErrorGlobalOrder
} from '@/api/dropShippingOrder/list';
import { getSignUrl } from '@/api/checkout';
export default {
  name: 'dropShippingOrder',
  data() {
    return {
      uploadExcelUrl: `${process.env.VUE_APP_BASE_API}/soyoungzg/api/dropshippingOrder/batchImportGlobal`,
      fileList: [],
      checkAll: false, // 是否全选
      options: [], // 错误订单索引集合(所有的)
      checkList: [], // 选中的错误订单集合
      isIndeterminate: false, // 是否半选
      showTable: false, // 是否展示订单列表
      listLoading: false,
      submitLoding: false,
      exportLoading: false,
      batchNo: '', // 导入批次号
      list: [], // 导入的订单列表
      errorArr: [], // 导入表格错误信息数组
      dialogVisible: false, // 是否展示错误信息弹窗
      totalDetail: {} // 单次导入的订单概况
    };
  },
  components: { UploadExcel },
  methods: {
    // 下载失败订单
    onExport() {
      this.exportLoading = true;
      exportErrorGlobalOrder(this.batchNo)
        .then((res) => {
          download(
            res,
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            `一件代发错误订单-${parseTime(Date.now(), '{y}-{m}-{d}-{h}:{i}:{s}')}.xlsx`
          );
        })
        .finally(() => {
          this.exportLoading = false;
        });
    },
    // 提交订单
    submit() {
      this.submitLoding = true;
      if (this.list.length === 0) {
        this.$message.error('订单内容为空，请重新上传之后再提交');
        this.submitLoding = false;
        return;
      }
      const tips = `本次订购商品${this.totalDetail.commodityQuantity}件，采购总价${this.totalDetail.totalAmount}元`;
      this.$confirm(tips, '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      })
        .then(() => {
          submitGlobalOrder(this.batchNo)
            .then((response) => {
              this.$message.success(response.msg);
              if (response.code === '0') {
                this.$router.push('/syoung/order/inquiry/list');
              }
            })
            .finally(() => {
              this.submitLoding = false;
            });
        })
        .catch(() => {
          this.$message.info('已取消提交订单');
          this.submitLoding = false;
        });
    },
    // 获取导入文件之后的导入批次号，并根据批次号请求订单列表数据
    getData(response, ...args) {
      // 如果导入不成功则弹出不成功提示
      if (response.code !== '0' && response.code !== 'UNSIGN001') {
        this.$message.error(response.msg);
        return;
      }
      if (response.code === 'UNSIGN001') {
        this.$confirm('您还未签署合同，请立即签署合同', '提示', {
          confirmButtonText: '立即签署',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            getSignUrl().then((res) => {
              window.open(res.data);
            });
          })
          .catch(() => {});
        return;
      }
      if (response.code === '800') {
        this.$message.error(response.msg);
        return false;
      }
      // 再次上传的表格有错误信息的时候，全选框默认不勾选
      this.checkAll = false;
      // 获取导入批次号
      this.batchNo = response.data.completeSuccess ? response.data.batchNum : '';
      if (this.batchNo) {
        this.fetchData();
        return;
      }
      // 获取错误提示
      this.errorArr = response.data.failedItems.map((item) => item.error);
      this.dialogVisible = true;
    },
    // 复选框全选
    handleCheckAllChange(val) {
      this.checkList = val ? this.options : [];
      this.isIndeterminate = false;
    },
    // 复选框选择改变
    handleCheckListChange(value) {
      const checkedCount = value.length;
      this.checkAll = checkedCount === this.options.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.options.length;
    },
    // 下载模板
    onDownload() {
      this.$confirm('是否下载模板?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        window.location.href = `
https://oss.syounggroup.com/static/file/soyoung-zg/aliyun/soyoung-zg/%E4%B8%80%E4%BB%B6%E4%BB%A3%E5%8F%91%E6%A8%A1%E6%9D%BF_%E6%B5%B7%E6%B7%98.xlsx`;
      });
    },
    // 获取订单列表
    fetchData() {
      this.listLoading = true;
      list({ batchNo: this.batchNo })
        .then((response) => {
          this.list = response.data;
          const arr = [];
          response.data.forEach((item, index) => {
            // 只有当订单状态为IMPORT_FAIL时才可以有复选框可以选中
            if (item.status === 'IMPORT_FAIL') {
              arr.push(item.id);
            }
            this.options = arr;
            this.fileList = [];
          });
          this.getTotal();
          this.showTable = true;
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    // 获取单次导入的概况
    getTotal() {
      getGlobalTotal(this.batchNo).then((response) => {
        this.totalDetail = response.data;
      });
    },
    // 删除
    handleDelete(val) {
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      })
        .then(() => {
          handleDelete(val)
            .then((response) => {
              this.fetchData();
            })
            .finally(() => {
              this.listLoading = false;
            });
          this.$message({
            type: 'success',
            message: '删除成功！'
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
    },
    // 批量删除
    handleDeleteIds() {
      if (this.checkList.length > 0) {
        this.$confirm('确认要批量删除吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true
        })
          .then(() => {
            handleDeleteIds(this.checkList)
              .then((response) => {
                this.fetchData();
              })
              .finally(() => {
                this.listLoading = false;
              });
            this.$message({
              type: 'success',
              message: '删除成功！'
            });
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            });
          });
        return;
      }
      this.$message({
        type: 'warning',
        message: '请先选择需要删除的内容'
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'src/styles/goods-table.scss';
@import './styles';
.dialog-word {
  /deep/ .el-dialog__body {
    max-height: 70vh;
    overflow-y: scroll;
  }
}
</style>
