import { withExtTenantIdRequest } from '@/utils/request';
// 确认订单-结算金额 普通商品&海淘
export function calculatePayment(data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/merchantOrder/calculatePayment',
    method: 'post',
    data
  });
}

// 确认订单-结算金额 小样
export function sampleCalculatePayment(sampleId) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/sampleApplyRecord/calculateAmount?sampleId=${sampleId}`,
    method: 'get',
  });
}

// 通过ids获取优惠券列表
export function couponList(data) {
  return withExtTenantIdRequest({
    url: `/marketing/api/userCoupon/listCheckOutCoupon`,
    method: 'post',
    data
  });
}
// 创建订单 普通
export function create(data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/merchantOrder/create',
    method: 'post',
    data
  });
}

// 创建订单 小样
export function createSampleApply(data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/sampleApplyRecord/create',
    method: 'post',
    data
  });
}

// 获取合同地址
export function getSignUrl() {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/distributorContract/getSignUrl',
    method: 'get'
  });
}
